import React from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux' //Provider
import store from './store'

import App from './App';

import reportWebVitals from './reportWebVitals';

import './index.css';
import './bootstrap.min.css'; 

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

  //This method is with Redux
  
  <Provider store={store}>
     <App /> {/*it calls App.js */}
   </Provider>

  //This method is without the Redux
  // <React.StrictMode>
  //   <App /> {/*it calls App.js */}
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
